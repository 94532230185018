import React from 'react'
import { Grid } from '@material-ui/core'
import { WrapperSection, WrapperContainer } from '../../../wrappers'
import SideLine from '../../../elements/SideLine/SideLine'
import { s, colors, alpha } from '../../../style'

const CarrierOffers = ({ data, theme }) => {
  const { title, slug_section, icons } = data

  return (
    <WrapperSection
      noPaddingTop
      id={slug_section}
      outside={<SideLine theme={theme}>{title}</SideLine>}>
      <WrapperContainer theme={theme}>
        <Grid container>
          {icons.map(({ file, title }, id) => (
            // <div css={sItem}>
            <Grid item xs={6} md={3} css={sItem} key={id}>
              <div css={sItem.imageContainer}>
                <img src={file.publicURL} alt={title} />
              </div>
              <div css={[sItem.title, sGradient(theme)]}>{title}</div>
            </Grid>
            // </div>
          ))}
        </Grid>
      </WrapperContainer>
    </WrapperSection>
  )
}

const sItem = {
  margin: '1.5rem 0',
  [s.md]: { margin: '2rem 0' },
  height: 'max-content',
  img: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  title: {
    fontWeight: 800,
    marginTop: '1rem',
    fontSize: '0.875rem',
    textAlign: 'center',
    [s.xs]: { fontSize: '0.875rem' },
    textTransform: 'uppercase',
  },
  imageContainer: {
    top: 0,
    left: 0,
    height: 64,
    width: '100%',
    position: 'relative',
    [s.md]: {
      height: 96,
    },
  },
}

const sGradient = (theme) => ({
  background: `linear-gradient(45deg, ${colors[theme].mainLighter.concat(
    alpha[100]
  )}, ${colors[theme].mainDarker.concat(alpha[100])})`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  fontFamily: 'Montserrat',
})

export default CarrierOffers
